export const experience = [
  {
    company: 'Kohai',
    link: 'https://kohai.com/',
    title: 'Software Engineer',
    date: '01/2022 - Current',
    desc: 'Using the existing CMS, PHP, Javascript and CSS, I rebuilt the company website UI/UX to include custom modules and functionalities resulting in an increase in user interactivity, new traffic sources and a doubling of online sales revenue.',
  },
  {
    company: 'E1 Creative',
    link: 'https://e1creative.com',
    title: 'Software Engineer',
    date: '2000 - Current',
    desc: 'Using React, React Native, Next.js, HTML, CSS, JavaScript and PHP, I design, develop and deploy web apps,  ranging from static single page apps to CRUD applications, to multi-page CMS-driven online stores, to help customers achieve goals, including increased site speeds, streamlined user flows,increased revenue and heightened image.',
  },
  {
    company: 'Springboard',
    link: null,
    title: 'Full Stack Software Engineer',
    date: '09/2022 - 07/2023',
    desc: 'Designed, engineered and deployed a web application using Python, Flask, an external and internal API and a PostgreSQL database that keeps a ledger of movies and tv shows for users regardless of the viewing  platform.',
    desc2:
      'Designed, engineered and deployed a web-based application using NodeJS, React.js, Next.js, Express.js, 2 external API’s, an internal API and a PostgreSQL database that serves as a peer-space for tuner car enthusiasts as well as an online catalog for companies looking to book tuner vehicles.',
  },
  {
    company: 'MindK',
    link: 'https://www.mindk.com/',
    title: 'Web Engineer',
    date: '05/2022 - 11/2022',
    desc: 'Implemented custom functionality to client websites using JavaScript, HTML, CSS and PHP,  resulting in increased usability for both clients and users, doubled user traffic sources and increased interaction.',
  },
  {
    company: 'DHD Global',
    link: null,
    title: 'Web Engineer',
    date: '10/2021 - 05/2022',
    desc: 'Rebuilt the company website using the existing CMS, JavaScript, HTML, CSS, SQL and PHP, integrating bespoke front-end and back-end modules, streamlining the UX,  resulting in increased website speed and 4x online sales.',
  },

  {
    company: 'Vizume',
    link: 'https://vizume.com',
    title: 'Webmaster/Product Designer',
    date: '09/2013 - Current',
    desc: 'Cultivated and managed a successful clothing boutique with consistent year-over-year revenue growth. Designed the company&apos;s website to  align seamlessly with the brand identity and boost online sales.',
  },
  {
    company: 'Event Production/Promotion',
    link: null,
    title: 'Owner',
    date: '01/2010 - Current',
    desc: 'Coordinated and promote events involving various international artists such as Epik High and Jay Park, at numerous venues in Hawaii.',
  },
];
