import "./Section.css";

function Section(props) {
  // console.log("from Section.js (props): ", props);

  const containerClassList = `Section__container Section__${
    props.sectionName
  }-container ${props.lightsOn ? " Section__container-glow" : ""}`;

  return (
    <section id={props.sectionName}>
      <div
        className={`Section__wrapper${
          props.sectionName === "intro" ? " Section__intro-wrapper" : ""
        }`}
      >
        <div className={containerClassList}>
          {/* include the nested html from each respective page component */}
          {props.children}
        </div>
      </div>
    </section>
  );
}

export default Section;
