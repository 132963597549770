import { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/free-solid-svg-icons';

import Page from './Page';
import './About.css';
import ExperienceItem from './ExperienceItem';
import { experience } from './data/experienceData';
import Proficiency from './Proficiency';
import { proficiencies } from './data/proficiencyData';

function About(props) {
  /**
   * setSectionNameState comes from App.js and is
   * used so we can pass the section name to our
   * header.  Without this, we have no way to pass
   * sectionName to our header since sectionName
   * is being passed explicitly to each route.
   */

  useEffect(() => {
    props.setSectionNameState(props.sectionName);
  });

  return (
    <Page
      sectionName={props.sectionName}
      pageTitle={props.pageTitle}
      lightsOn={props.lightsOn}
    >
      <h1 className='Section__heading'>{props.sectionHeading}&nbsp;</h1>
      <p className='Section__heading-blurb'>
        Self-taught software engineer with experience in both front-end and
        back-end development. My diverse background of 10+ years also includes
        entrepreneurship, design, and marketing. I excel in creative
        problem-solving, am adept at rapid learning, and am equally effective
        working independently or collaboratively with a team.
      </p>

      {/* begin work experience section */}
      <div className='Section__content-container'>
        <h2 className='Section__content-title'>Experience</h2>

        {experience.map((e, i) => (
          <ExperienceItem
            key={i}
            company={e.company}
            link={e.link}
            title={e.title}
            date={e.date}
            desc={e.desc}
            desc2={e.desc2}
          />
        ))}
      </div>

      {/* begin proficiencies section */}
      <div className='Section__content-container'>
        <h2 className='Section__content-title'>Proficiencies</h2>
        <div className='Section__content-item-container About__content-skills-container'>
          {proficiencies.map((p, i) => (
            <Proficiency key={i} proficiency={p} />
          ))}
        </div>
        <div className='Section__content-item-container'>
          <h3 className='Section__content-item-title About__content-item-title Section__content-item-title--no-date'>
            Languages
          </h3>
          <p className='Section__content-item-desc About__content-item-desc'>
            HTML5, CSS3, Javascript, Python, PHP, SQL, Jinja, JSX
          </p>
        </div>
        <div className='Section__content-item-container'>
          <h3 className='Section__content-item-title About__content-item-title Section__content-item-title--no-date'>
            Frontend
          </h3>
          <p className='Section__content-item-desc About__content-item-desc'>
            NodeJS, ReactJS, NextJS, Wordpress, REST Api's
          </p>
        </div>
        <div className='Section__content-item-container'>
          <h3 className='Section__content-item-title About__content-item-title Section__content-item-title--no-date'>
            Backend
          </h3>
          <p className='Section__content-item-desc About__content-item-desc'>
            NodeJS, Flask, ExpressJS, API Development, MySQL, PostgresSQL
          </p>
        </div>
      </div>

      {/* begin education section */}
      <div className='Section__content-container'>
        <h2 className='Section__content-title'>Education</h2>
        <div className='Section__content-item-container'>
          <h3 className='Section__content-item-title About__content-item-title Section__content-item-title--no-date'>
            Springboard Software Engineering Course
          </h3>
          <p className='Section__content-item-desc About__content-item-desc'>
            Certificate of Completion
            <a
              className='Section__content-item-title-link'
              href='./9r9nbp8u_1689106002019.pdf'
              target='_blank'
              rel='noreferrer'
            >
              <FontAwesomeIcon
                icon={faLink}
                className='Section__content-item-title-link-icon'
              />
            </a>
          </p>
        </div>
        <div className='Section__content-item-container'>
          <h3 className='Section__content-item-title About__content-item-title Section__content-item-title--no-date'>
            University of Oregon (Eugene, Oregon)
          </h3>
          <p className='Section__content-item-desc About__content-item-desc'>
            Bachelors of Science - Business
          </p>
        </div>
      </div>

      {/* begin other experience section */}
      <div className='Section__content-container'>
        <h2 className='Section__content-title'>Other Interests</h2>
        <div className='Section__content-item-container'>
          <h3 className='Section__content-item-title About__content-item-title Section__content-item-title--no-date'>
            Jaytee (the DJ)
          </h3>
          <p className='Section__content-item-desc About__content-item-desc'>
            I also DJ. I've been a resident at several clubs in Hawaii, played
            at a number of venues in Japan, Taiwan, Korea, and also played at
            the Ultra Music Festival Korea. Over the years, I've organized
            several "tours" to Asia with other DJ friends.
          </p>
        </div>
      </div>
    </Page>
  );
}

export default About;
