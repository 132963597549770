import React, { createContext, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import './App.css';
import Header from './Header';
import Intro from './Intro';
import About from './About';
import Projects from './Projects';
import Contact from './Contact';
import Link from './Link';
import Footer from './Footer';

import Starfield from 'react-starfield';

export const ThemeContext = createContext();

function App() {
  const [sectionNameState, setSectionNameState] = useState(null);
  const [lightsOn, setLightsOn] = useState(
    JSON.parse(localStorage.getItem('lightsOn')) || false
  );
  const [spaceOn, setSpaceOn] = useState(
    JSON.parse(localStorage.getItem('spaceOn')) || false
  );

  const contextVal = { testVal: true };

  /**
   * function to update lightsOn state. and store
   * the value in localStorage.
   */

  const toggleLights = (e) => {
    // console.log("from toggleLights(e)", e.target.id);

    if (e.target.id === 'spaceButton' || e.target.id === 'spaceButtonDesktop') {
      setSpaceOn(!spaceOn);
      localStorage.setItem('lightsOn', JSON.stringify(!lightsOn));
    } else if (
      e.target.id === 'lightButton' ||
      e.target.id === 'lightButtonDesktop'
    ) {
      setLightsOn(!lightsOn);
      localStorage.setItem('lightsOn', JSON.stringify(!lightsOn));
    }
  };

  return (
    <BrowserRouter>
      <Starfield
        starCount={1000}
        starColor={[255, 255, 255]}
        speedFactor={0.05}
        zIndex={-1}
      />
      <ThemeContext.Provider value={contextVal}>
        <Header
          toggleLights={toggleLights}
          lightsOn={lightsOn}
          spaceOn={spaceOn}
          sectionNameState={sectionNameState}
        />
      </ThemeContext.Provider>
      <Routes>
        <Route
          path='/'
          element={
            <Intro
              pageTitle='Hello'
              sectionName='intro'
              sectionHeading='Jaytee'
              lightsOn={spaceOn}
              setSectionNameState={setSectionNameState}
            />
          }
        ></Route>
        <Route
          path='/about'
          element={
            <About
              pageTitle='Who Is Jaytee?'
              sectionName='about'
              sectionHeading='Who Is Jaytee?'
              lightsOn={lightsOn}
              setSectionNameState={setSectionNameState}
            />
          }
        ></Route>
        <Route
          path='/projects'
          element={
            <Projects
              pageTitle='Projects'
              sectionName='projects'
              sectionHeading='Projects'
              lightsOn={lightsOn}
              setSectionNameState={setSectionNameState}
            />
          }
        ></Route>
        <Route
          path='/contact'
          element={
            <Contact
              pageTitle='Contact Me'
              sectionName='contact'
              sectionHeading='Contact'
              lightsOn={lightsOn}
              setSectionNameState={setSectionNameState}
            />
          }
        ></Route>
        <Route
          path='/link'
          element={
            <Link
              pageTitle='Link'
              sectionName='link'
              sectionHeading='Link'
              lightsOn={lightsOn}
              setSectionNameState={setSectionNameState}
            />
          }
        ></Route>
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
