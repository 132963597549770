import './Footer.css';

function Footer() {
  const showEmail = () => {
    const emailAddress = document.getElementById('emailAddress');

    emailAddress.classList.toggle('Footer__email-address--show');
  };

  return (
    <footer className='Footer__container'>
      <div className='Footer__email'>
        <button
          id='emailButton'
          onClick={showEmail}
          className='Footer__email-button'
        >
          ✉️
        </button>
        <div className='Footer__email-address-container'>
          <div id='emailAddress' className='Footer__email-address'>
            hello@whoisjaytee.com
          </div>
        </div>
      </div>
      <div className='Footer__copyright'>
        &copy; {new Date().getFullYear()} WhoIsJaytee.com
      </div>
    </footer>
  );
}

export default Footer;
