import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/free-solid-svg-icons';

const ExperienceItem = ({ company, link, title, date, desc, desc2 }) => {
  return (
    <div className='Section__content-item-container'>
      <h3 className='Section__content-item-title About__content-item-title'>
        {company}
        {link ? (
          <a
            className='Section__content-item-title-link'
            href={link}
            target='_blank'
            rel='noreferrer'
          >
            <FontAwesomeIcon
              icon={faLink}
              className='Section__content-item-title-link-icon'
            />
          </a>
        ) : null}
      </h3>
      <p className='Section__content-item-subtitle'>{title}</p>
      <p className='Section__content-item-date'>{date}</p>
      <p className='Section__content-item-desc'>{desc}</p>
      {desc2 ? <p className='Section__content-item-desc'>{desc2}</p> : null}
    </div>
  );
};

export default ExperienceItem;
