import { useEffect } from 'react';
import Page from './Page';
import Project from './Project';
import './Projects.css';

// import our project data arrays
import { projectsPersonal, projectsWork } from './data/projectData';

function Projects(props) {
  /**
   * setSectionNameState comes from App.js and is
   * used so we can pass the section name to our
   * header.  Without this, we have no way to pass
   * sectionName to our header since sectionName
   * is being passed explicitly to each route.
   */
  useEffect(() => {
    props.setSectionNameState(props.sectionName);
  });

  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      console.log(entry);
      if (entry.isIntersecting) {
        entry.target.classList.add('in-view');
      } else {
        entry.target.classList.remove('in-view');
      }
    });
  });

  const els = document.querySelectorAll('.el');
  els.forEach((el) => observer.observe(el));

  return (
    <Page
      sectionName={props.sectionName}
      pageTitle={props.pageTitle}
      lightsOn={props.lightsOn}
    >
      <h1 className='Section__heading'>{props.sectionHeading}&nbsp;</h1>
      <p className='Section__heading-blurb'>
        A few personal and client-work projects.
      </p>

      {/* begin personal projects */}
      <div className='Section__content-container'>
        <h2 className='Section__content-title'>Personal Projects</h2>

        {projectsPersonal.map((p, i) => (
          <Project
            key={i}
            projectTitle={p.projectTitle}
            projectDesc={p.projectDesc}
            projectUrl={p.projectUrl}
          />
        ))}
      </div>

      {/* begin client work projects */}
      <div className='Section__content-container'>
        <h2 className='Section__content-title'>Client Projects</h2>

        {projectsWork.map((p, i) => (
          <Project
            key={i}
            projectTitle={p.projectTitle}
            projectDesc={p.projectDesc}
            projectUrl={p.projectUrl}
          />
        ))}
      </div>
    </Page>
  );
}

export default Projects;
