import { useEffect } from "react";

import Section from "./Section";

function Page(props) {
  // console.log("from Page.js (props): ", props);

  useEffect(() => {
    // console.log("...in Page.js");
    // console.log("running useEffect");
    document.title = `Jaytee - Software Engineer | ${props.pageTitle}`;
    window.scrollTo(0, 0);
  }, [props.pageTitle]);

  return (
    <Section sectionName={props.sectionName} lightsOn={props.lightsOn}>
      {/* include the nested html from each respective file */}
      {props.children}
    </Section>
  );
}

export default Page;
