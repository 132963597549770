export const proficiencies = [
  'html5',
  'css3',
  'sass',
  'js',
  'node',
  'react',
  'python',
  'wordpress',
  'elementor',
];
