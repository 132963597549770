import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faLinkedinIn, faGithub } from "@fortawesome/free-brands-svg-icons";

import Page from "./Page";
import "./Contact.css";

function Contact(props) {
  /**
   * setSectionNameState comes from App.js and is
   * used so we can pass the section name to our
   * header.  Without this, we have no way to pass
   * sectionName to our header since sectionName
   * is being passed explicitly to each route.
   */
  useEffect(() => {
    props.setSectionNameState(props.sectionName);
  });

  return (
    <Page
      sectionName={props.sectionName}
      pageTitle={props.pageTitle}
      lightsOn={props.lightsOn}
    >
      <h1 className="Section__heading">{props.sectionHeading}&nbsp;</h1>
      <div className="Contact__method-container">
        <FontAwesomeIcon icon={faEnvelope} className="Contact__method-icon" />
        <p className="Contact__method">hello@whoisjaytee.com</p>
      </div>
      <div className="Contact__method-container">
        <a
          href="https://linkedin.com/in/jmtakahashi"
          target="_blank"
          rel="noreferrer"
        >
          <FontAwesomeIcon
            icon={faLinkedinIn}
            className="Contact__method-icon"
          />
        </a>
        <p className="Contact__method">@jmtakahashi</p>
      </div>
      <div className="Contact__method-container">
        <a
          href="https://github.com/jmtakahashi"
          target="_blank"
          rel="noreferrer"
        >
          <FontAwesomeIcon icon={faGithub} className="Contact__method-icon" />
        </a>
        <p className="Contact__method">@jmtakahashi</p>
      </div>
      <p className="Contact__fine-print">
        Please don't email me if you are selling anything. I don't want it.
        Please don't email me if you're the estate lawyer of the Nigerian King
        and have $1,000,000,000,000. I have enough SPAM® at home.
      </p>
    </Page>
  );
}

export default Contact;
