import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHtml5,
  faCss3,
  faSass,
  faJs,
  faNode,
  faReact,
  faWordpress,
  faPython,
  faElementor,
} from '@fortawesome/free-brands-svg-icons';

const Proficiency = ({ proficiency }) => {
  let iconCode;

  switch (proficiency) {
    case 'html5':
      iconCode = faHtml5;
      break;
    case 'css3':
      iconCode = faCss3;
      break;
    case 'sass':
      iconCode = faSass;
      break;
    case 'js':
      iconCode = faJs;
      break;
    case 'node':
      iconCode = faNode;
      break;
    case 'react':
      iconCode = faReact;
      break;
    case 'python':
      iconCode = faPython;
      break;
    case 'wordpress':
      iconCode = faWordpress;
      break;
    case 'elementor':
      iconCode = faElementor;
      break;

    default:
      iconCode = '';
  }

  return (
    <FontAwesomeIcon icon={iconCode} className='About__content-skills-icon' />
  );
};

export default Proficiency;
