import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/free-solid-svg-icons';

const Project = ({ projectTitle, projectDesc, projectUrl }) => {
  return (
    <div className='Section__content-item-container'>
      <div className='Projects__project-container'>
        <h3 className='Section__content-item-title Section__content-item-title--no-date'>
          {projectTitle}
          <a
            className='Section__content-item-title-link'
            href={projectUrl}
            target='_blank'
            rel='noreferrer noopener'
          >
            <FontAwesomeIcon
              icon={faLink}
              className='Section__content-item-title-link-icon'
            />
          </a>
        </h3>
        <p className='Section__content-item-desc'>{projectDesc}</p>
      </div>
    </div>
  );
};

export default Project;
