import { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEnvelope,
  faX,
  faXmarkCircle,
} from '@fortawesome/free-solid-svg-icons';
import {
  faLinkedinIn,
  faGithub,
  faYoutube,
  faSoundcloud,
  faMixcloud,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons';

import Page from './Page';
import './Link.css';

function Link(props) {
  /**
   * setSectionNameState comes from App.js and is
   * used so we can pass the section name to our
   * header.  Without this, we have no way to pass
   * sectionName to our header since sectionName
   * is being passed explicitly to each route.
   */
  useEffect(() => {
    props.setSectionNameState(props.sectionName);
  });

  return (
    <Page
      sectionName={props.sectionName}
      pageTitle={props.pageTitle}
      lightsOn={props.lightsOn}
    >
      <h1 className='Section__heading'>{props.sectionHeading}&nbsp;</h1>

      <div className='Section__content-container'>
        <h2>Office Hours Mix Links</h2>
        <a
          href='https://on.soundcloud.com/PX6HBWhpPFu3FRo49'
          className='Link__method-container'
        >
          <FontAwesomeIcon icon={faSoundcloud} className='Link__method-icon' />
          <p className='Link__method'>Soundcloud</p>
        </a>
        <a
          href='https://youtu.be/TeMFvi9MMvg'
          className='Link__method-container'
        >
          <FontAwesomeIcon icon={faYoutube} className='Link__method-icon' />
          <p className='Link__method'>Youtube</p>
        </a>
        <a
          href='https://www.mixcloud.com/whoisjaytee/jaytee-office-hours-20240612/'
          className='Link__method-container'
        >
          <FontAwesomeIcon icon={faMixcloud} className='Link__method-icon' />
          <p className='Link__method'>Mixcloud</p>
        </a>
      </div>

      <div className='Section__content-container'>
        <h2>Live House Mix Links</h2>
        <a
          href='https://soundcloud.com/whoisjaytee/jaytee-house-mix-20240413'
          className='Link__method-container'
        >
          <FontAwesomeIcon icon={faSoundcloud} className='Link__method-icon' />
          <p className='Link__method'>Soundcloud</p>
        </a>
        <a
          href='https://youtu.be/2zyR8ui7uP4'
          className='Link__method-container'
        >
          <FontAwesomeIcon icon={faYoutube} className='Link__method-icon' />
          <p className='Link__method'>Youtube</p>
        </a>
        <a
          href='https://www.mixcloud.com/whoisjaytee/jaytee-house-mix-live-04132024/'
          className='Link__method-container'
        >
          <FontAwesomeIcon icon={faMixcloud} className='Link__method-icon' />
          <p className='Link__method'>Mixcloud</p>
        </a>
      </div>

      <div className='Section__content-container'>
        <h2>Work</h2>
        <a
          href='https://github.com/jmtakahashi'
          className='Link__method-container'
        >
          <FontAwesomeIcon icon={faGithub} className='Link__method-icon' />
          <p className='Link__method'>@jmtakahashi</p>
        </a>
        <a
          href='https://linkedin.com/in/jmtakahashi'
          className='Link__method-container'
        >
          <FontAwesomeIcon icon={faLinkedinIn} className='Link__method-icon' />
          <p className='Link__method'>@jmtakahashi</p>
        </a>
      </div>
    </Page>
  );
}

export default Link;
