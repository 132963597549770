import { Link } from "react-router-dom";

import "./Header.css";

function Header({ toggleLights, lightsOn, spaceOn, sectionNameState }) {
  // console.log("from Header.js (sectionName): ", sectionNameState);

  const toggleMenu = (e) => {
    const menu = document.getElementById("menu");
    const mobileMenuToggle = document.getElementById("mobileMenuToggle");
    // console.log(e.target + " clicked");

    menu.classList.toggle("Header__nav-menu--open");
    mobileMenuToggle.classList.toggle("Header__nav-mobile-toggle--open");
  };

  return (
    <header className="Header">
      <div className="Header__logo-container">
        <div className="Header__logo">
          <Link to="/" className="Header__logo-link">
            Hello
          </Link>
        </div>
        {sectionNameState !== "intro" ? (
          <button
            id="lightButtonDesktop"
            onClick={toggleLights}
            className={`Header__nav-button Header__nav-button--lights-desktop ${
              lightsOn ? " Header__nav-button--lights-on" : ""
            } `}
          >
            💡
          </button>
        ) : (
          <button
            id="spaceButtonDesktop"
            onClick={toggleLights}
            className="Header__nav-button Header__nav-button--lights-desktop"
          >
            🚀
          </button>
        )}
      </div>
      <nav className="Header__nav-container">
        <div className="Header__nav-buttons-container">
          {sectionNameState === "intro" ? (
            <button
              id="spaceButton"
              onClick={toggleLights}
              className={`Header__nav-button Header__nav-button--lights ${
                spaceOn ? " Header__nav-button--lights-on" : ""
              }`}
            >
              🚀
            </button>
          ) : (
            <button
              id="lightButton"
              onClick={toggleLights}
              className={`Header__nav-button Header__nav-button--lights ${
                lightsOn ? " Header__nav-button--lights-on" : ""
              } `}
            >
              💡
            </button>
          )}
          <button
            id="menuButton"
            onClick={toggleMenu}
            className="Header__nav-button Header__nav-button--menu"
          >
            <div
              id="mobileMenuToggle"
              className="Header__nav-mobile-toggle"
            ></div>
          </button>
        </div>
        <ul id="menu" className="Header__nav-menu" onClick={toggleMenu}>
          <li className="Header__nav-item">
            <Link to="/projects" className="Header__nav-link">
              Projects
            </Link>
          </li>
          <li className="Header__nav-item">
            <Link to="/about" className="Header__nav-link">
              About Me
            </Link>
          </li>
          <li className="Header__nav-item">
            <Link to="/contact" className="Header__nav-link">
              Contact
            </Link>
          </li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;
