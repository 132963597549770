export const projectsPersonal = [
  {
    projectTitle: 'Tensorcalc',
    projectDesc:
      'A project for the Tensor community that tracks earnings for users that own a Tensorian NFT.  Integrates with the Coingecko API and the Tensor API.',
    projectUrl: 'https://tensorcalc.com',
  },
  {
    projectTitle: 'Checkphone',
    projectDesc:
      'A project for the Solana community with web3 integration that garners over 100k views a month. Integrates the Solana RPC, Tensor API and Magic Eden API.',
    projectUrl: 'https://checkphone.xyz',
  },
  {
    projectTitle: 'My Movie Ledger',
    projectDesc:
      "A web-based app that keeps track of movies, series and documentaries that you've watched regardless of platform.",
    projectUrl: 'https://movie-ledger-frontend.onrender.com/',
  },
];

export const projectsWork = [
  {
    projectTitle: 'Ala Moana Beach Surf Lessons',
    projectDesc: 'Website Design + Buildout',
    projectUrl: 'https://alamoanabeachsurflessons.com',
  },

  {
    projectTitle: 'Ann Otani',
    projectDesc: 'Website Design + Buildout',
    projectUrl: 'https://annotani.com',
  },

  {
    projectTitle: 'Navigate Consulting',
    projectDesc: 'Single page site for a staffing company.',
    projectUrl: 'http://navigateconsultinghi.com',
  },

  {
    projectTitle: 'Steak Teppei',
    projectDesc: 'Website Design + Buildout',
    projectUrl: 'https://teppei-usa.com',
  },
];
